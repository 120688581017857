import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
// Create a new store instance.
const store = new Vuex.Store({
  plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
  state() {
    return {
      user: null,
      token: null,
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {},
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    token(state) {
        return state.token;
    },
  },
});
export default store;
