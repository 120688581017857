<template>
  <div>
    <Navbar />
    <v-container fluid class="align-start d-flex  flex-row">
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        dark
        class="mr-2"
        style=" height :auto"
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon dense>mdi mdi-comment-account-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-title>Admin user</v-list-item-title>

          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            @click="$router.push({ path: item.route })"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      drawer: true,
      items: [
        { title: " Dashboard", icon: "mdi-home-city", route: "/about" },
        {
          title: "Companies",
          icon: "mdi-account-group-outline",
          route: "/companies",
        },
      ],
      mini: true,
    };
  },
};
</script>

<style></style>
