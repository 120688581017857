<template>

<div>
  <p class="title">
    <span class="font-weight-light">IOTA</span>
    <span>ORIGIN Authority</span>
  </p>
   <p v-if="serviceerrors.length" class="title1">
            <ul>
                <li v-for="e in serviceerrors" :key="e.id" style="color: red;"> 
                    {{e}}
                </li>
            </ul>
    </p> 

     <form  class="box">
    <v-text-field
      v-model="name"
      :error-messages="nameErrors"
      label="Username"
      required
      @input="$v.name.$touch()"
      @blur="$v.name.$touch()"
    ></v-text-field>
    <v-text-field
      v-model="password"
      :error-messages="passwordErrors"
      :type="password ? 'password' : 'text'"
      label="Password"
      required
      @input="$v.password.$touch()"
      @blur="$v.password.$touch()"
    ></v-text-field>
    <v-checkbox
      v-model="checkbox"
      :error-messages="checkboxErrors"
      label=" Remeber me"
      required
      @change="$v.checkbox.$touch()"
      @blur="$v.checkbox.$touch()"
    ></v-checkbox>
    <v-btn color="#048b9a"
      class="mr-4"
      @click="submit"
    >
      Login
    </v-btn>

  </form>
</div>
 
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import axios from 'axios';
  import { mapMutations } from "vuex"; 
  import  { sha256 } from 'js-sha256';

  export default {
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(10) },
      password: { required }, checkbox: {
        checked (val) {
          return val
        },
      },
    },

    data: () => ({
      TILTE : process.env.VUE_APP_TITLE ,
      PROXY_URL: process.env.VUE_APP_PROXY_URL ,
      name: '',
      password: '',
      checkbox: {
        checked (val) {
          return val
        },
      },

      serviceerrors: [],
    }),

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('username is required.')
        return errors
      },
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('password is required')
        return errors
      },
    },

    methods: {
      ...mapMutations(["setUser", "setToken"]),
      submit () {
        this.$v.$touch()
        var _this = this
            //var token_id = '' ;
        axios.post(this.PROXY_URL+'/api/authenticate',{
            username:this.name,
            password:sha256(this.password),
            rememberMe: this.checkbox.checked
        }).then( res => {
            _this.setToken(res.data.id_token);
            _this.$emit("authenticated", true);
            _this.$router.replace({ name: "Companies" });
        }).catch(function(reason)  {
            console.log(reason.response);
            _this.serviceerrors = [];
            _this.serviceerrors.push(reason.response.data.detail)
        })
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.email = ''
        this.select = null
        this.checkbox = false
      },
    },
  }
</script>

<style>
.box {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    align-content: stretch;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 5%;
}
.title {
    display: flex;
    align-content: space-around;
    justify-content: center;
    flex-wrap: nowrap;
    padding-top: 20%;

}

.title1 {
    display: flex;
    align-content: space-around;    
    flex-wrap: nowrap;
    justify-content: center;
}
</style>