<template>
  <nav>
    <v-toolbar flat app>
    
    <v-toolbar-title class="text-uppercase grey--text">
    <span class="font-weight-light">IOTA</span>
    <span>ORIGIN</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat color="#048b9a" @click="logout">
        <span>Sign Out</span>
      </v-btn>
    </v-toolbar>
  </nav>
</template>

<script>
 import { mapMutations } from "vuex"; 
export default {
  
    data() {
    return {
      drawer: false
    }
  
  },
  methods : {
     ...mapMutations([ "setToken"]),
    logout() {
      console.log("logout")
       this.setToken(null);
       this.$router.push({ path: '/' })
    },
    
  }
  
}
</script>

<style>

</style>