<template>
  <div class="main-display">
    <v-data-table
      :headers="headers"
      :items="desserts"
      item-key="id"
      v-model="selectedRows"
      class="elevation-6"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Companies </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }" class="companies-list">
        <tr
          v-if="displaydata"
          :class="selectedRows.indexOf(item.id) > -1 ? 'cyan' : ''"
          @click="showAlert(item)"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.companyCode }}</td>
          <td>{{ item.accs[0].username }}</td>
          <td>{{ item.accs[0].email }}</td>
          <td>
            {{ item.accs[0].phone ? item.accs[0].phone : "No phone" }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <Tranfert v-if="displayTransfer" v-bind:company="selectedCompany" />
  </div>
</template>

<script>
import store from "../store/index.js";
import axios from "axios";
import Tranfert from "./TransfertList.vue";
export default {
  components: { Tranfert },

  data: () => ({
    ACCOUNT: process.env.VUE_APP_ACCOUNT_URL,
    dialog: false,
    dialogDelete: false,
    displayTransfer: false,
    displaydata: false,
    isEditing: true,
    selectedCompany: {
      companyCode: "",
      id: 0,
      name: "",
    },
    headers: [
      {
        text: "id",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "name", value: "name", sortable: false },
      { text: "companyCode", value: "companyCode", sortable: false },
      { text: "Username", value: "", sortable: false },
      { text: "Email", value: "", sortable: false },
      { text: "Phone", value: "", sortable: false },
      //{ text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    selectedRows: [],
    editedIndex: -1,
    editedItem: {
      companyCode: "",
      contentId: "",
      cooperativeCode: "",
      id: 0,
      name: "",
      parentCompanyCode: "",
      productionCapacity: "",
      state: "",
    },
    defaultItem: {
      companyCode: "",
      contentId: "",
      cooperativeCode: "",
      id: 0,
      name: "",
      parentCompanyCode: "",
      productionCapacity: "",
      state: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    $route: "getCompaniesFromServer",
  },

  created() {
    this.getCompaniesFromServer();
  },
  beforeRouteEnter(to, from, next) {
    if (to.path == "/companies") {
      next((vm) => vm.getCompaniesFromServer());
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path == "/companies") {
      this.getCompaniesFromServer();
      next();
    }
  },
  beforeMount() {
    //this.getCompaniesFromServer();
    // window.addEventListener("beforeunload", this.preventNav);
  },

  mounted() {},
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      this.getCompaniesFromServer();
      event.returnValue = "";
    },
    getCompaniesFromServer() {
      var _this = this;
      console.log("token " + store.getters["token"]);
      axios
        .get(this.ACCOUNT + "/api/companies/confirm-companies?page=0&size=50", {
          headers: {
            Authorization: `Bearer ${store.getters["token"]}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          _this.defaultItem = response.data[0];
          var id = _this.defaultItem.id;
          var name = _this.defaultItem.name;
          var companyCode = _this.defaultItem.companyCode;
          _this.selectedCompany = {
            id,
            name,
            companyCode,
          };
          _this.toggleSelection(companyCode.id);
          _this.selectedRows.push();
          _this.desserts = response.data;
          _this.displaydata = true;
          _this.displayTransfer = true;
        })

        .catch((error) => {
          console.log(error);
        });
    },
    showAlert(a) {
      if (event.target.classList.contains("btn__content")) return;
      this.toggleSelection(a.id);
      this.selectedCompany = a;
    },

    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows = [];
        this.selectedRows.push(keyID);
      }
    },
  },
};
</script>

<style>
.main-display {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.companies-list {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media only screen and (max-width: 700px) {
  .main-display {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .companies-list {
    width: 100%;
    height: auto;
  }
}
</style>
