<template>
  <div class="transfert-list">
    <v-container style="height=400px;">
      <v-row justify="space-around">
        <v-card :loading="loading">
          <v-card-text>
            <v-card-title> Transfert for {{ company.name }} </v-card-title>

            <v-timeline align-top dense>
              <v-timeline-item
                v-for="message in transferts"
                :key="message.id"
                small
                :color="message.color"
              >
                <v-card elevation="3">
                  <v-card-title>
                    {{ message.transaction.type_transaction }}
                  </v-card-title>
                  <v-card-text>
                    <div class="font-weight-normal">
                      On :
                      <strong>{{ message.date_time | moment }}</strong>
                      <br />
                      <span
                        v-if="message.transaction.type_transaction === 'DEBIT'"
                      >
                        To:
                      </span>
                      <span
                        v-else-if="
                          message.transaction.type_transaction === 'CREDIT'
                        "
                      >
                        From :</span
                      >
                      <span v-else>Init : </span>
                      {{ message.transaction.recipient }} with
                      <strong>
                        {{ message.transaction.value_certificate_transfer }}
                      </strong>

                      certificats
                      <br />
                      Value :
                      <strong
                        ><span> {{ message.transaction.amount }} </span>
                      </strong>
                      <span>{{ message.transaction.currency }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import store from "../store/index.js";
export default {
  props: {
    company: {
      companyCode: String,
      id: Number,
      name: String,
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    },
  },
  watch: {
    $props: {
      handler() {
        this.getTransfertAPI(this.company.companyCode);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    currentDateTime() {
      return moment().format("MMMM Do YYYY, h:mm:ss a");
    },
    getTransfertAPI(companyCode) {
      var _this = this;
      console.log("token " + store.getters["token"]);
      console.log(companyCode);
      this.loading = true;
      axios
        .get(
          this.SUPPLY_CHANGE_URL + "/api/transastion/history/" + companyCode,
          {
            headers: {
              Authorization: `Bearer ${store.getters["token"]}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          _this.loading = false;
          _this.transferts = response.data;
          // _this.fakeData();
          _this.addColor(_this.transferts);
        })

        .catch((error) => {
          _this.loading = false;
          console.log(error);
          // _this.fakeData();
          // _this.addColor(_this.transferts);
        });
    },

    addColor(transfert) {
      var list = [];
      transfert.forEach((element) => {
        if (element.transaction.type_transaction == "CREDIT") {
          element.color = "red";
          list.push(element);
        }
        if (element.transaction.type_transaction == "DEBIT") {
          element.color = "green";
          list.push(element);
        }
        if (element.transaction.type_transaction == "INITIALISATION") {
          element.color = "black";
          list.push(element);
        }
      });
      this.transferts = list;
    },
  },
  data: () => ({
    loading: false,
    SUPPLY_CHANGE_URL: process.env.VUE_APP_SUPPLY_CHANGE_URL,
    transferts: [],
  }),
};
</script>

<style>
.transfert-list {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 700px;
  overflow-y: scroll;
}
</style>
