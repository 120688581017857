import Vue from 'vue'
import  VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Companies from '../views/Companies.vue'
import OtherCompanies from '../views/OthersCompanies.vue'
import store from '../store/index.js'
import Dashboard from '../components/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  },
  {
    path: '/dasboart',
    name: 'dasboart',
    component: Dashboard,
    children: [
      {
        // `UserProfile` va être rendu à l'intérieur du `<router-view>` de `User`
        // quand `/utilisateur/:id/profil` concorde
        path: '/about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/companies',
        name: 'Companies',
        component: Companies
      },
      {
        path: '/allcompanies',
        name: 'allCompanies',
        component: OtherCompanies
      }
    ]
  
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode : 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {


  if (to.path == '/companies') {
    if(store.getters['isLoggedIn'] === true) {
      console.log(store.getters['token'])
      next()
    }
   }else if (from.path == '/companies') {
    if (store.getters['isLoggedIn'] === true) {
      console.log(store.getters['token'])
      next();
    }
  }
  next();
})
export default router
