<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="calories"
    class="elevation-1" style="width: 100%; height: 100%"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>All Companies </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              v-show="false"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="confirmCompany">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
     <template v-slot:item.actions="{ item }">
       <v-btn class="ma-2" color="#A30000" dark  @click="deleteItem(item)">Suspend
        <v-icon dark right> mdi-close-circle</v-icon>
      </v-btn>
    </template> 
  </v-data-table>
</template>

<script>
import store from '../store/index.js'
 import axios from 'axios';
  export default {
    data: () => ({
      ACCOUNT: process.env.VUE_APP_ACCOUNT_URL ,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'name', value: 'name',sortable: false },
        { text: 'companyCode', value: 'companyCode',sortable: false },
        { text: 'state', value: 'state',sortable: false },
        { text: 'Document', value: 'contentId',sortable: false },
        { text: 'Parent', value: 'parentCompanyCode',sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        companyCode: '',
        contentId: '',
        cooperativeCode: '',
        id: 0,
        name: '',
        parentCompanyCode: '',
        productionCapacity: '',
        state: '',
      },
      defaultItem: {
        companyCode: '',
        contentId: '',
        cooperativeCode: '',
        id: 0,
        name: '',
        parentCompanyCode: '',
        productionCapacity: '',
        state: '',

      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.getCompaniesFromServer()
    },

    methods: {

        getCompaniesFromServer() {
            var _this = this;
            console.log("token "+ store.getters['token'])
            axios.get(this.ACCOUNT+'/api/companies?page=0&size=50', {headers : { 
            'Authorization' : `Bearer ${store.getters['token']}`,
            'Content-Type': 'application/json'
            }})
            .then((response) => {
                console.log(response.data)
                _this.desserts = response.data
            })
            
            .catch((error) => {
                console.log(error);
            })
        },
      

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    
    confirmCompany () {
       const item = this.desserts[this.editedIndex];
        var _this = this;
            console.log("token "+ store.getters['token'])
            axios.patch(this.ACCOUNT+'/api/companies/'+item.id+'/banned',{}, {headers : { 
            'Authorization' : `Bearer ${store.getters['token']}`,
            'Content-Type': 'application/json'
            }})
            .then((response) => {
                if(response.status == 200 )
                _this.getCompaniesFromServer()
            })
            
            .catch((error) => {
                console.log(error);
            })
        ///
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>

<style>

</style>